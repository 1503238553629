'use client';

import React, { useEffect, useState } from 'react';
import { MAX_FREE_COUNT } from '@/constant';
import { Zap } from 'lucide-react';
import { useProModal } from '@/hooks/use-pro-modal';
import { Card, CardContent } from './ui/card';
import { Progress } from './ui/progress';
import { Button } from './ui/button';

interface FreeCounterprops {
  apiLimitCount: number,
  isPaid: boolean,
  bgColor: string,
}
const FreeCounter = ({
  apiLimitCount = 0,
  isPaid = false,
  bgColor = 'white/10',

}: FreeCounterprops) => {
  const proModal = useProModal();
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  if (!mounted) {
    return null;
  }
  if (isPaid) {
    return null;
  }
  return (
    <Card className={`bg-${bgColor} border-0 w-[100%]`}>
      <CardContent className="py-4">
        <div className="text-sm text-center text-white mb-4 space-y-2">
          <p>
            {apiLimitCount}
            {' '}
            /
            {' '}
            {MAX_FREE_COUNT}
            {' '}
            Free Recipes
          </p>
          <Progress
            className="h-3"
            value={(apiLimitCount / MAX_FREE_COUNT) * 100}
          />
        </div>
        <Button
          variant="premium"
          className="w-full"
          onClick={proModal.onOpen}
        >
          Upgrade
          <Zap className="w-4 h-4 ml-2 fill-white" />
        </Button>
      </CardContent>
    </Card>
  );
};
export default FreeCounter;
