'use client';

import Sidebar from '@/components/sidebar';
import React, { useEffect, useState } from 'react';
import { Menu } from 'lucide-react';
import Link from 'next/link';
import Image from 'next/image';
import { Button } from './ui/button';
import {
  Sheet,
  SheetContent,
  SheetTrigger,
} from './ui/sheet';

interface MobileSidebarProps {
  apiLimitCount: number,
  isPaid: boolean
}
const MobileSidebar = ({
  apiLimitCount,
  isPaid = false,
}: MobileSidebarProps) => {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);
  if (!isMounted) {
    return null;
  }
  return (
    <Sheet>
      <SheetTrigger>
        <Button variant="ghost" size="icon" className="md:hidden w-8"   
        aria-label="Toggle menu"
        >
          <Menu className="h-8 w-8  text-gray-100" />
        </Button>
      </SheetTrigger>
      <SheetContent side="left" className="pl-0 bg-deep-blue">
        <div className="flex px-6">
          <Link href="/">
            <Image
              src="/cookgpt_logo.png"
              alt="cookgpt_logo"
              className="h-[35px] min-w-[140px]"
              width={45}
              height={37}
            />
          </Link>
        </div>
        <Sidebar isPaid={isPaid} apiLimitCount={apiLimitCount} bgColor="" />
      </SheetContent>
    </Sheet>
  );
};
export default MobileSidebar;
