'use client';

import React from 'react';
import { TbLayoutDashboardFilled } from 'react-icons/tb';
import Link from 'next/link';
import { BiSolidConversation, BiSolidWine } from 'react-icons/bi';
import { FaHistory } from 'react-icons/fa';
import { IoSettings } from 'react-icons/io5';
import { cn } from '@/lib/utils';
import { usePathname } from 'next/navigation';
import FreeCounter from './free-counter';

interface SidebarProps {
  apiLimitCount: number,
  isPaid: boolean,
  bgColor: string
}
const Sidebar = ({
  apiLimitCount = 0,
  isPaid = false,
  bgColor = 'white/10',
}: SidebarProps) => {
  const routes = [
    {
      label: 'Dashboard',
      icon: TbLayoutDashboardFilled,
      href: '/dashboard',
      color: 'text-sky-500',
    },
    {
      label: 'Create Recipe',
      icon: BiSolidConversation,
      href: '/genrecipe',
      color: 'text-deep-green',
    },
    {
      label: 'Create Cocktail',
      icon: BiSolidWine,
      href: '/gencocktail',
      color: 'text-yellow',
    },
    {
      label: 'Recipe History',
      icon: FaHistory,
      href: '/history',
      color: 'text-violet-400',
    },

    {
      label: 'Settings',
      icon: IoSettings,
      href: 'settings',
      color: '',
    },
  ];
  const pathname = usePathname();

  return (
    <div className="px-4 lg:px-6 space-y-4 py-4   flex flex-col bg-[#11827] text-gray-200">

      <div className="px-3 py-8 flex-1">
        <div className="space-y-1">
          {routes.map((route) => (
            <Link
              href={route.href}
              key={route.href}
              className={cn(`text-base font-lato group flex p-3 w-full justify-start
              font-medium cursor-pointer hover:text-white hover:bg-white/10 rounded-lg transition`, pathname === route.href ? 'text-gray-100 bgc-color' : 'text-zinc-400')}
            >
              <div className="flex items-center flex-1">
                <route.icon className={cn('h-5 w-5 mr-3', route.color)} />
                {route.label}
              </div>
            </Link>
          ))}

        </div>

      </div>
      <div className=" lg:flex justify-center items-center w-full px-2 ">
        <FreeCounter isPaid={isPaid} apiLimitCount={apiLimitCount} bgColor={bgColor} />
      </div>
    </div>
  );
};
export default Sidebar;
